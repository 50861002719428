import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import facedetect from "../../Assets/Projects/facedetect.png";
import bitcoin from "../../Assets/Projects/bitcoin.png";
import sales from "../../Assets/Projects/sales.png";
import anomaly from "../../Assets/Projects/anomaly.png";
import heart from "../../Assets/Projects/heart.png";
import salary from "../../Assets/Projects/salary.png";


function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={anomaly}
              isBlog={false}
              title="Anomaly Detection and Event Prediction in Sensor Networks"
              description="Our client, a leading provider of innovative sensor network solutions, aims to revolutionize sensor network technology. This project focuses on developing an Anomaly Detection and Event Prediction system for sensor networks. The goal is to create a system capable of detecting anomalies and predicting events in real-time, enabling proactive responses to potential issues, and significantly enhancing monitoring capabilities within sensor networks."
              ghLink="https://github.com/AnAi05/Anomaly-Detection-and-Event-Prediction-in-sensor-networks"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={heart}
              isBlog={false}
              title="Heart Disease Prediction"
              description="The primary objective of this project is to build and evaluate machine learning models for predicting the presence of heart disease based on a given set of features such as age, sex, blood pressure, cholesterol levels, and other relevant medical data. The goal is to achieve high accuracy while prioritizing sensitivity (recall) to minimize the risk of misdiagnosing individuals with heart disease."
              ghLink="https://github.com/AnAi05/Heart-Disease-Prediction"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={salary}
              isBlog={false}
              title="Salary Range Prediction"
              description="
Develop a machine learning algorithm to forecast salary ranges for job listings based on title, category, and location. This model will align postings with market standards, attract top talent, and support HR in budget planning. Ensure high accuracy, scalability, and provide detailed documentation for future reference."
              ghLink="https://github.com/AnAi05/Salary-Range-Prediction"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={sales}
              isBlog={false}
              title="Sales Prediction Analysis"
              description="The process examine the historical and current sales data sets to predict sales outcomes and enhance business performance. The method uses statistical modeling, data mining techniques and machine learning to find patterns in different data sets."
              ghLink="https://github.com/AnAi05/Sales-Prediction-Analysis"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={bitcoin}
              isBlog={false}
              title="Bitcoin Price Prediction using LSTM"
              description="The LSTM model implemented here is a basic model that takes into consideration only a few features that affect the Bitcoin price. Our future work would include in depth scrutinisation on the topic of LSTM and deep learning at large. Such fact findings would be beneficial for forecasting the prices of cryptocurrencies with the help of LSTMs in the future."
              ghLink="https://github.com/AnAi05/Bitcoin-Price-Prediction-using-LSTM"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={facedetect}
              isBlog={false}
              title="Face Recognition"
              description="Face Detection and Recognition is one of the areas of computer vision where the research actively happens. Human brain is trained for face detection and recognition. detection and recognition is an easy task for humans."
              ghLink="https://github.com/AnAi05/Face-Detection"
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;

import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            Namaste, I am <span className="purple"> Om Jajulwar </span>
            hailing from <span className="purple"> Nagpur, India.</span>
            <br />
            So folks! I'm all about the data analyst gig. I've got a degree in
            Computer Science and Engineering, and now I'm exploring the exciting
            world of Data and AI.
            <br />
            <br />
            During my downtime, I enjoy taking on challenging business case
            studies. I'm also exploring Advaita Vedanta as a way to better
            understand myself and how I can contribute to helping others.
            <br />
            <br />
            In moments of respite from Data and other gigs, you'll find me
            immersed in a multitude of activities
          </p>
          <ul>
            <li className="about-activity">
              <ImPointRight /> Reading Books
            </li>
            <li className="about-activity">
              <ImPointRight /> Writing Vedanta Blogs
            </li>
            <li className="about-activity">
              <ImPointRight /> Travelling
            </li>
          </ul>

          <p style={{ color: "rgb(155 126 172)" }}>
            "Unleashing the Potential of Bits and Bytes!"{" "}
          </p>
          <footer className="blockquote-footer">OJ</footer>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
